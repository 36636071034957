import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div>
      <div>
        <div>
          <div className="text-center m-4">
            <h1 className="text-2xl font-bold">
              404 - Not Found
            </h1>
            <p>
              The page you were looking for was not found.
            </p>
            <p>
              <Link to="/" className="text-blue-500 underline">Login</Link> into to your account
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
