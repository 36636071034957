import { useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { createSearchParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../api";
import Button from "../components/Button";
import Input from "../components/Input";
import animejs from 'animejs'
import OtpInput from 'react-otp-input';
import '../styles/OtpStyles.css'
import { useNavigate } from "react-router-dom";


export default function ForgotPassword() {

    const [user, setUser] = useState("")
    const [email, setEmail] = useState("")
    const [searching, setSearching] = useState(false)
    const [otp, setOtp] = useState("")

    const userDom = useRef(null)
    const otpDom = useRef(null)
    const changePasswordDom = useRef(null)
    
    const [failed, setFailed] = useState(false)
    const [sending, setSending] = useState(false)
    const [validating, setValidating] = useState(false)
    const [saving, setSaving] = useState(false)

    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    
    const navigate = useNavigate()

    const saveNewPassword = ()=>{
        if(!saving){
            setSaving(true)
            if (newPassword  && confirmNewPassword){

                if (newPassword === confirmNewPassword) {
                    console.log('continue!')
                    api.post('/user/reset-password', {
                        user: user,
                        code: otp,
                        password: newPassword,
                        confirmPassword: confirmNewPassword
                    }).then(({data})=>{
                        if (data.success){
                            toast.success('Your password has been updated.')
                            navigate({
                                pathname: '/',
                                search: createSearchParams({reset: 1}).toString()
                            })
                        }else{
                            toast.error('Your password could not be updated. Try again later')
                        }

                    }).catch((e)=>{
                        let message = 'Could not update your password. Try again later - ' + e?.message
                        if (Array.isArray(e?.response?.data?.errors) && e?.response?.data?.errors?.length > 0){
                            message = e?.response?.data?.errors[0].message
                        }
                        toast.error(message)
                    }).finally(()=>{
                        setSaving(false)
                    })
                }else{
                    toast.error("Your password and confirmation do not match.")
                    setSaving(false)
                }

            }else{
                toast.error("PLease enter your password in both fields!")
                setSaving(false)
            }
        }
    }

    const showOtp = ()=>{
        if (userDom.current){
            animejs({
                targets: userDom.current,
                height: '0px',
                opacity: 0,
                duration: 500,
                easing: 'easeInOutQuad',
                complete: ()=>{
                    userDom.current.style.display = 'none'
                }
            })
        }
        if (otpDom.current){
            animejs({
                targets: otpDom.current,
                height: '100%',
                opacity: 1,
                duration: 500,
                easing: 'easeInOutQuad'
            })
        }
        sendCode()
    }

    const failedToSend = (e) => {
        const failedToSend = `Failed to send the recovery code. ` + (e?.message || '')
        setFailed(true)
        toast.error(failedToSend)
        setSending(false)
    }

    const sendCode = ()=>{
        if(!sending){
            console.log('Sending code...')
            setSending(true)
            api.post('/user/send-code', {
                user: user
            }).then(({data})=>{
                console.log('then')
                if (data.sent){
                    toast.success(`A 6 digit code has been sent to your email address. (${email})`)
                    setFailed(false)
                    setSending(false)
                }else{
                    failedToSend()
                }
                setSending(false)
            }).catch((e)=>{
                console.log('Catch')
                failedToSend(e)
                setSending(false)
            }).finally(()=>{
                console.log('Finally')
                setSending(false)
            })
        }
  
    }

    const serchUserName = (e) => {
        e.preventDefault()

        if (user.length > 0) {
            setSearching(true)
            const notFoundMsg = "We could not find your record by username or by email. Please make sure you typed the right value."
            api.post('/user/search/', {
                user
            }).then(({ data }) => {
                if (data.found) {
                    if (data.email){
                        console.log('Has an email')
                        setEmail(data.email)
                        showOtp()
                    }else{
                        toast.error(`Your account does not have an email address associated. Send an email at info@realmezcal.com with your details and your email address.`)
                    }
                } else {
                    toast.error(notFoundMsg)
                }
            }).catch((e) => {
                if (e?.response?.status === 404) {
                    toast.error(notFoundMsg)
                } else {
                    toast.error(`Something went wrong. ` + (e?.message || ''))
                }
            }).finally(() => {
                setSearching(false)
            })
        } else {
            toast.error('Enter your username or your email address.')
            setSearching(false)
        }
    }

    const confirmCode = ()=>{
        if(otp.length === 6){
            if(!validating){
                setValidating(true)
                api.post('/user/validate-code/', {
                    user,
                    code: otp,
                }).then(({data})=>{
                    if (data.valid){
                        console.log('Continue')
                        
                        if (otpDom.current){
                            animejs({
                                targets: otpDom.current,
                                height: '0px',
                                opacity: 0,
                                duration: 500,
                                translateY: '-300px',
                                easing: 'easeInOutQuad',
                                complete: ()=>{
                                    otpDom.current.style.display = 'none'
                                }
                            })
                        }
                        if (changePasswordDom.current){
                            animejs({
                                targets: changePasswordDom.current,
                                height: '100%',
                                opacity: 1,
                                duration: 500,
                                easing: 'easeInOutQuad'
                            })
                        }
                    }else{
                        toast.error("The code is not valid.")
                    }
                }).catch((e)=>{
                    toast.error("Failed to validate the code. " + (e?.message || ""))
                }).finally(()=>{
                    setValidating(false)
                })
            }
        }else{
            toast.error("The code must have a length of 6 digits.")
        }
    }

    return <>
        <Helmet>
            <title>
                Forgot my password
            </title>
        </Helmet>
        <div className="flex justify-center bg-[#eee] min-w-full min-h-screen">
            <div className="mt-4 md:mt-16 lg:mt-20">
                <div className="border border-gray bg-white flex min-w-[300px] md:min-w-[400px] max-w-full rounded shadow">
                    <div
                        className="grid grid-cols-1 gap-4 p-4 w-full"
                    >
                        <div>
                            <div className="flex justify-center mb-3">
                                <img src="/logo.png" alt="logo" width="200" height={"auto"} />
                            </div>
                            <p className="text-center text-denim">
                                Password Recovery
                            </p>
                        </div>
                        <div ref={userDom}>
                            <div className="my-3">
                                <label htmlFor="username" className="mb-0">
                                    Username / Email Address
                                </label>
                                <Input
                                    placeholder="username"
                                    id="username"
                                    value={user}
                                    onChange={(e) => {
                                        setUser(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="my-3">
                                <Button type={"button"}  disabled={user.length <= 0} isLoading={searching} onClick={serchUserName}>
                                    Send Code
                                </Button>
                            </div>
                            <div className="text-center my-3">
                                <Link className="text-blue underline" to={"/"}>
                                    Go Back
                                </Link>
                            </div>
                        </div>
                        <div ref={otpDom} style={{height: 0, opacity: 0}}>
                            <div className="mb-3">
                                <label htmlFor="username" className="mb-0">
                                    {failed ? <>
                                        <p className="text-red-700">Failed to send the code.</p>
                                       
                                    </> : <>
                                        {sending ? 'Sending code...' : `Code sent to ${email}`}
                                    </>}
                                </label>
                                <Input value={email} disabled/>
                                {failed &&  <Button isLoading={sending} className="my-3" onClick={sendCode} type={"button"}>Re Try</Button>}
                            </div>
                           {sending ? <>
                           </> : <>
                           {!failed && <>
                            <p>
                                Enter The code we sent you to your email address.
                            </p>
                            <OtpInput
                                containerStyle={"otp-container"}
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                separator={<></>}
                            />
                            <div className="my-3">
                                <Button type={"button"} disabled={otp.length !== 6} onClick={confirmCode} isLoading={validating}>
                                    Confirm 
                                </Button>
                            </div></>}
                           </>}
                        </div>
                        <div ref={changePasswordDom} style={{height: 0, opacity: 0}}>
                            <div className="my-3">
                                <label htmlFor="newPassword">
                                    New Password
                                </label>
                                <Input placeholder={"New password"} type="password" id={"newPassword"} value={newPassword} onChange={(e)=>setNewPassword(e.target.value)}/>
                            </div>
                            <div className="my-3">
                                <label htmlFor="confirmNewPassword">
                                    Enter your password again
                                </label>
                                <Input placeholder={"Confirm your new password"} type="password" id={"confirmNewPassword"} value={confirmNewPassword} onChange={(e)=>setConfirmNewPassword(e.target.value)}/>
                            </div>
                            <div className="my-3">
                                <Button type={"button"} isLoading={saving} onClick={saveNewPassword}>
                                    Save new password
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}