import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./views/Login";
import SignOut from "./views/Logout";
import NotFound from "./views/NotFound";
import ForgotPassword from "./views/ForgotPassword";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/sign-out" element={<SignOut />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword/>}></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
