import { useState, useEffect, useCallback } from "react";
import Input from "../components/Input";
import api from "../api";
import { Link, useSearchParams } from "react-router-dom";
import useValidator from "../hooks/useValidator";
import Button from "../components/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bowser from "bowser";
import {Buffer} from 'buffer';

const defaultRedirect = process.env.REACT_APP_DEFAULT_REDIRECT_LOGIN + "/login";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const validator = useValidator();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [callback, setCallback] = useState(null);
  const [isGoToWebsite, setIsGoToWebsite] = useState(false);


  const [browserName, setBrowserName] = useState("")
  const [browserVersion, setBrowserVersion] = useState("")
  const [osName, setOsName] = useState("")
  const [osVersion, setOsVersion] = useState("")
  const [platformType, setPlatformType] = useState("")
  const [loadingBrowserInfo, setLoadingBrowserInfo] = useState(true)

  const [recoveredPassword, setRecoveredPassword] = useState(false)
  

  useEffect(()=>{

    if (searchParams.get('reset') === "1"){
      setRecoveredPassword(true)
    }

  }, [searchParams])
  
  const getBrowsersInfo = useCallback(()=>{

    setLoadingBrowserInfo(true)
    const browser = Bowser.getParser(window.navigator.userAgent)
    setBrowserName(browser.getBrowserName())
    setBrowserVersion(browser.getBrowserVersion())
    setOsName(browser.getOSName())
    setOsVersion(browser.getOSVersion())
    setPlatformType(browser.getPlatformType())
    setLoadingBrowserInfo(false)

  }, [])

  useEffect(() => {
    getBrowsersInfo();
  }, [getBrowsersInfo]);

  useEffect(() => {
    let cb = searchParams.get("callback");
    let website = searchParams.get("website");

    if (cb && validator.validateURL(cb)) {
      setCallback(cb);
    }
    if (website === "1" || website === 1) {
      setIsGoToWebsite(true);
    }
    // let sign_out = searchParams.get("sign_out");
    return () => {
      setCallback(null);
      setIsGoToWebsite(false);
    };
  }, [searchParams, validator]);

  const handleErrors = useCallback((e)=>{
    showError(e?.response?.data?.message || "Unknown Error");
  }, [])

  const showError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleSignOn = async (e) => {
    if (!loading) {
      e.preventDefault();
      if (username.length > 0 && password.length > 0) {
        try {
          setLoading(true);
          await api.post(
            "/user/passport-login",
            {
              username,
              password,
            },
            { withCredentials: true }
          );
          redirectUser({
            wasManuallyCalled: true,
          });
        } catch (e) {
          handleErrors(e);
        }
        setLoading(false);
      } else {
        toast.error("Enter your credentials!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const redirectUser = useCallback(
    ({ wasManuallyCalled }) => {
      api
        .get(
          "/user/has-session",
          {
            domain: defaultRedirect,
          },
          { withCredentials: true }
        )
        .then(({ data }) => {
          if (wasManuallyCalled && !data.user) {
            showError(
              "Unable to get your session. Please make sure you have cookies enabled or try a different web browser"
            );
          }
          if (data && data.user) {
            const role = data.user.role;
            const defaultRedirect =
              process.env.REACT_APP_DEFAULT_REDIRECT_LOGIN + "/login";
            const defaultRedirectWebsite =
              process.env.REACT_APP_DEFAULT_REDIRECT_WEBSITE +
              "/dashboard/login";

            api
              .post(
                `${process.env.REACT_APP_AUTH_API}/sessions/new`,
                {
                  domain: defaultRedirect,
                  bs: Buffer.from(JSON.stringify({browserName: browserName || '', browserVersion : browserVersion || '', osName: osName || '', osVersion : osVersion || '', platformType: platformType || ''}))
                            .toString('base64')
                },
                { withCredentials: true }
              )
              .then(({ data }) => {
                let redirectUserTo = defaultRedirect;


                if (["BLOG_EDITOR"].includes(role) || isGoToWebsite) {
                  // override URL even if there is a callback.
                  redirectUserTo = defaultRedirectWebsite;
                }

                // Remove / at the end becase we are adding it anyways.
                if (redirectUserTo.charAt(redirectUserTo.length - 1) === "/") {
                  redirectUserTo = redirectUserTo.substring(
                    0,
                    redirectUserTo.length - 1
                  );
                }
                redirectUserTo = redirectUserTo + "/" + data.session_id;
                if (callback != null) {
                  redirectUserTo += `?callback=${callback}`;
                }
                window.location.replace(redirectUserTo);
              })
              .catch((e) => handleErrors(e));
          }
        });
    },
    [callback, isGoToWebsite,handleErrors, browserName, browserVersion, osName, osVersion, platformType]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      redirectUser({
        wasManuallyCalled: false,
      });
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [redirectUser]);

  return (
    <>

      <div className="flex justify-center bg-[#eee] min-w-full min-h-screen">
        <div className="mt-4 md:mt-16 lg:mt-20">
          <div className="border border-gray bg-white flex min-w-[300px] md:min-w-[400px] max-w-full rounded shadow">
            <form
              onSubmit={handleSignOn}
              className="grid grid-cols-1 gap-4 p-4 w-full"
            >
              <div className="flex justify-center">
                <img src="/logo.png" alt="logo" width="200" height={"auto"} />
              </div>
              {recoveredPassword && <div className="my-3">
                <div className="bg-green-500 text-white p-3">
                  <p className="text-center">
                    Your password was successfully updated. <br/>You can now login using your new password.
                  </p>
                </div>
              </div>}
              <div>
                <label htmlFor="username" className="mb-0">
                  Username
                </label>
                <Input
                  placeholder="username"
                  id="username"
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="username" className="mb-0">
                  Password
                </label>
                <Input
                  placeholder="Password"
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>
              <div>
                <Button isLoading={loading || loadingBrowserInfo} type="submit" disabled={browserName === "" && browserVersion === "" && osName === "" && osVersion === "" && platformType === ""}>
                  {loading ? "Signing on..." : "Sign On"}
                </Button>
              </div>
              <div className="text-center">
                  <Link to={"/forgot-password"} className='text-blue underline'>Forgot your password?</Link>
              </div>
              <div className="text-center">
                <span className="text-gray-400">-Or-</span>
              </div>
              <div>
                <Button
                  onClick={() => {
                    window.location.assign(
                      `${process.env.REACT_APP_DEFAULT_REDIRECT_LOGIN}/sign-up`
                    );
                  }}
                  className="bg-white text-black border border-gray-400"
                >
                  Sign Up
                </Button>
              </div>

              <div className="text-center">
                <span className="text-gray-400">
                  Visit our{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                    href={process.env.REACT_APP_DEFAULT_REDIRECT_WEBSITE}
                  >
                    Website
                  </a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
