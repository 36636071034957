import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import useValidator from "../hooks/useValidator";

const Logout = () => {
  const [status, setStatus] = useState("Signing out...");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const validator = useValidator()

  useEffect(() => {
    let callback = "";
    if (searchParams.get("callback")) {
      callback = searchParams.get("callback");
    }

    axios({
      method: "DELETE", 
      url: `${process.env.REACT_APP_AUTH_API}/user/passport-sign-out?sessionId=${searchParams.get('sessionId') || ''}`,
      withCredentials: true,
    })
      .then(() => {
        setStatus("Signed out.");
        if (validator.validateURL(callback)) {
          navigate({
            pathname: "/",
            search: `?callback=${encodeURIComponent(callback)}`,
          });
        } else {
          if (searchParams.get("website")) {
            navigate({
              pathname: "/",
              search: '?website=1'
            });
          } else {
            navigate({
              pathname: "/",
            });
          }
        }

      })
      .catch(() => {
        setStatus("Failed to sign out. Try again");
        /*navigate({
          pathname: "/",
          search: `?callback=${encodeURIComponent(callback)}`,
        });
        */
      });
  }, [searchParams, navigate, validator]);

  return (
    <>
      {status}
    </>
  );
};

export default Logout;
